import React from "react";
import { Container, Nav } from "react-bootstrap";
import {
  Link45deg,
  PeopleFill,
  PersonVcardFill,
  Robot,
  ShieldFillCheck,
  ShieldLockFill,
  Upload,
} from "react-bootstrap-icons";
import { Helmet } from "react-helmet";
import { BiLastPage, BiSolidCameraHome } from "react-icons/bi";
import { Link, Outlet, useLocation } from "react-router-dom";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import useUserData from "../../components/useUserDetails/useUserDetails";

const AdminPage = () => {
  const location = useLocation();

  const siteName = SubDomainRouter("title");
  const userData = useUserData();
  const role = userData.role;

  return (
    <>
      <Helmet>
        <title>Admin | {siteName ? siteName : window.location.host}</title>
      </Helmet>

      <div className="mt-4 pt-5 dashboardSec">
        <div className="d-flex flex-md-row flex-column">
          <div className="dashboard-nav px-0 py-md-5">
            <div className="d-flex flex-md-column pb-md-5 gap-md-0 gap-4 justify-content-center">
              {role === "admin" || role === "siteadmin" || role === "superadmin" ?
                <>
                  <Nav.Item>
                    <Link
                      to="/admin/users"
                      className={`nav-link d-flex text-capitalize align-items-center flex-column gap-1 text-center ${location.pathname === "/admin/users" && "active"
                        }`}
                    >
                      <PeopleFill /> Users
                    </Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Link
                      to="/admin/registrants"
                      className={`nav-link d-flex text-capitalize align-items-center flex-column gap-1 text-center ${location.pathname === "/admin/registrants" && "active"
                        }`}
                    >
                      <PersonVcardFill /> Registrants
                    </Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Link
                      to="/admin/configs"
                      className={`nav-link d-flex text-capitalize align-items-center flex-column gap-1 text-center ${location.pathname === "/admin/configs" && "active"
                        }`}
                    >
                      <ShieldLockFill /> Configs
                    </Link>
                  </Nav.Item>
                </> : null
              }
              <Nav.Item>
                <Link
                  to="/admin/content-generator"
                  className={`nav-link d-flex text-capitalize align-items-center flex-column gap-1 text-center ${location.pathname === "/admin/content-generator" && "active"
                    }`}
                >
                  <Robot /> Content Generator
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link
                  to="/admin/upload"
                  className={`nav-link d-flex text-capitalize align-items-center flex-column gap-1 text-center ${location.pathname === "/admin/upload" && "active"
                    }`}
                >
                  <Upload /> Uploader
                </Link>
              </Nav.Item>

              {role === "siteadmin" || role === "superadmin" || role === "uidev" ? (
                <>
                  <Nav.Item>
                    <Link
                      to="/admin/super-admin-configs"
                      className={`nav-link d-flex text-capitalize align-items-center flex-column gap-1 text-center ${location.pathname === "/admin/super-admin-configs" && "active"
                        }`}
                    >
                      <ShieldFillCheck /> Admin Configs
                    </Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Link
                      to="/admin/pages"
                      className={`nav-link d-flex text-capitalize align-items-center flex-column gap-1 text-center ${location.pathname === "/admin/pages" && "active"
                        }`}
                    >
                      <BiLastPage /> Pages
                    </Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Link
                      to="/admin/homepages"
                      className={`nav-link d-flex text-capitalize align-items-center flex-column gap-1 text-center ${location.pathname === "/admin/homepages" && "active"
                        }`}
                    >
                      <BiSolidCameraHome /> Homepages
                    </Link>
                  </Nav.Item>
                </>
              ) : null}

              {role === "admin" || role === "siteadmin" || role === "superadmin" ?
                <Nav.Item>
                  <Link
                    to="/admin/api-integrations"
                    className={`nav-link d-flex text-capitalize align-items-center flex-column gap-1 text-center ${location.pathname === "/admin/api-integrations" && "active"
                      }`}
                  >
                    <Link45deg /> API Integrations
                  </Link>
                </Nav.Item> : null}
            </div>
          </div>
          <Container fluid="lg" className="px-lg-4 pb-5 pt-5 flex-grow-1">
            <Outlet />
          </Container>
        </div>
      </div>
    </>
  );
};

export default AdminPage;
