import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import { BiEdit, BiTrash } from 'react-icons/bi'
import { Link } from 'react-router-dom'

const JSONSchemasListing = () => {
    const entityType = "schemas";

    const [entityData, setEntityData] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await fetch(
                `https://api.ameerpetit.com/api/entities/schemas`
            );
            const jsonResponse = await response.json();
            setEntityData(jsonResponse);
        } catch (error) {
            console.error("Error fetching Data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [entityType]);

    // function to delete item from database
    const deleteItem = async (id) => {

        const confirm = window.confirm("Are you sure you want to delete this item?")

        if (confirm) {
            axios.delete(
                `https://api.ameerpetit.com/api/entities/schemas/${id}`
            ).then(() => {
                alert("Item deleted successfully");
                fetchData();
            }).catch((error) => {
                console.error("Error deleting item:", error);
            });

        }
    };

    return (
        <Container fluid="lg" className="py-5 my-5">
            <div className='d-flex justify-content-between align-items-center'>   <h1 className='text-theme mb-3 text-capitalize'>Schemas</h1>


                <Link to={`/${entityType}/add`} className='add-button btn text-capitalize'>Add {entityType}</Link>

            </div>

            {loading ? <p>Loading...</p> :
                <Row className="d-flex justify-content-center align-items-center">
                    {entityData?.length > 0 ? entityData?.map((data, index) => (

                        <Col sm={6} md={4} xl={3} className='py-2' key={index}>
                            <Card className="text-center" style={{ borderRadius: '15px' }}>
                                <Card.Body>
                                    <h4 className="mb-2">{data?.display_name}</h4>
                                    <p className="text-muted mb-4">
                                        {data?.host}
                                    </p>



                                    <div className='d-flex gap-2 justify-content-center'>
                                        <Button onClick={() => deleteItem(data?.type)} className="btn-rounded py-1 d-flex align-items-center justify-content-center btn btn-danger px-2">
                                            <BiTrash className='fs-6' />
                                        </Button>

                                        <Link to={`/${entityType}/edit/${data?.type}`} className="btn-rounded py-1 d-flex align-items-center justify-content-center btn add-button w-auto px-2">
                                            <BiEdit className='fs-6' />
                                        </Link>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    )
                    )
                        : <p>No data Found</p>}
                </Row>
            }
        </Container>

    )
}

export default JSONSchemasListing
