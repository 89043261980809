import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Accordion, Button, Col, Container, Form, Row } from 'react-bootstrap';
import { ArrowRight, Check2Circle } from 'react-bootstrap-icons';
import { confirmAlert } from 'react-confirm-alert';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DynamicNavItemFieldset from '../../../components/dynamicFieldset/dynamicNavItemsFieldset';
import DynamicOptionsFieldset from '../../../components/dynamicOptionsFieldset/dynamicOptionsFieldset';
import { NetworkErrorAlert } from '../../../components/infoPopup/infoPopup';
import CustomLoader from '../../../components/loader/loader';
import SubDomainRouter from '../../../components/subDomainRouter/subDomainRouter';
import TokenDetails from '../../../components/useUserDetails/useTokenDetails';
import * as Actions from "../../../constants/baseUrl";
import { configDataStructure } from '../../../constants/configDataStructure';
import DynamicGeneralFieldset from '../../../components/dynamicFieldset/dynamicFieldset';
import DynamicUserRoleFieldset from '../../../components/dynamicOptionsFieldset/DynamicUserRoleFieldset';

const AdminConfigsPage = () => {
    const tokenDetails = TokenDetails();
    const myUserId = tokenDetails?.Email;
    const navigate = useNavigate();


    const demosAlias = SubDomainRouter("demosAlias");
    const coursesAlias = SubDomainRouter("coursesAlias");
    const cohortsAlias = SubDomainRouter("cohortsAlias");
    const codeAIAlias = SubDomainRouter("codeAIAlias");
    const startupsAlias = SubDomainRouter("startupsAlias");
    const propertyEventsAlias = SubDomainRouter("propertyEventsAlias");
    const propertiesAlias = SubDomainRouter("propertiesAlias");
    const trainersAlias = SubDomainRouter("trainersAlias");
    const eventsAlias = SubDomainRouter("eventsAlias");

    const countryTypeFilterAlias = SubDomainRouter("countryTypeFilterAlias");
    const coursesFilterAlias = SubDomainRouter("coursesFilterAlias");
    const learningLevelFilterAlias = SubDomainRouter("learningLevelFilterAlias");
    const paymentFilterAlias = SubDomainRouter("paymentFilterAlias");
    const teachingModesFilterAlias = SubDomainRouter("teachingModesFilterAlias");
    const ratingsFilterAlias = SubDomainRouter("ratingsFilterAlias");

    const [loading, setLoading] = useState(true);
    const [step, setStep] = useState(1);

    const [reqLoading, setReqLoading] = useState(false);
    const [reqDomainData, setReqDomainData] = useState({});
    const [customFields, setCustomFields] = useState([]);
    const [siteConfigData, setSiteConfigData] = useState()
    const [domainFormData, setDomainFormData] = useState({
        userId: myUserId,
        createdBy: myUserId,
        modifiedBy: myUserId,
        ...configDataStructure,
    });
    const [navItems, setNavItems] = useState([
        { path: "/", label: "Home" },
        { path: "/", label: "" },
    ]);

    const [userRole, setUserRole] = useState([{ key: "user", value: "user" }, { key: "", value: "" }]);
    const [dynamicUrls, setDynamicUrls] = useState([{ key: "", value: "" }]);


    const [countryTypes, setCountryTypes] = useState({ 0: "" });
    const [courseNames, setCourseNames] = useState({ 0: "" });
    const [subcategoryTypes, setSubcategoryTypes] = useState({ 0: "" });
    const [teachingModes, setTeachingModes] = useState({ 0: "" });
    const [paymentTypes, setPaymentTypes] = useState({ 0: "" });
    const [starRatings, setStarRatings] = useState({ 0: "" });
    const [basicPackage, setBasicPackage] = useState({ 0: "" });
    const [proPackage, setProPackage] = useState({ 0: "" });
    const [masterPackage, setMasterPackage] = useState({ 0: "" });
    const [heroSliderImages, setHeroSliderImages] = useState({ 0: "" });

    const myToken = localStorage.getItem("token");

    const populateList = async () => {
        axios
            .get(`${Actions.BASE_URL}configs`, {
                headers: {
                    Authorization: "Bearer " + myToken,
                },
            })
            .then((response) => {
                setDomainFormData(response?.data?.appsettings);
                setSiteConfigData(response?.data?.siteconfig);
                setReqDomainData(response?.data);
                setLoading(false);
                setNavItems(
                    response?.data?.appsettingsarrayobjects?.navItems ?? [
                        { path: "/", label: "Home" },
                        { path: "/", label: "" },
                    ]
                );


                setUserRole(
                    response.data?.appsettingsarrayobjects?.userRole || [
                        { key: "user", value: "user" },
                        { key: "", value: "" },
                    ]
                );

                setDynamicUrls(
                    response.data?.appsettingsarrayobjects?.dynamicUrls || [
                        { key: "", value: "" },
                    ]
                );

                setCountryTypes(
                    response?.data?.categorysettings?.countryTypes ?? { 100: "" }
                );
                setCourseNames(
                    response?.data?.categorysettings?.courseNames ?? { 100: "" }
                );
                setSubcategoryTypes(
                    response?.data?.categorysettings?.subcategoryTypes ?? { 100: "" }
                );
                setTeachingModes(
                    response?.data?.categorysettings?.teachingModes ?? { 0: "" }
                );
                setPaymentTypes(
                    response?.data?.categorysettings?.paymentTypes ?? { 0: "" }
                );
                setStarRatings(
                    response?.data?.categorysettings?.starRatings ?? { 0: "" }
                );

                setBasicPackage(response.data?.categorysettings?.basicPackage);
                setProPackage(response.data?.categorysettings?.proPackage);
                setMasterPackage(response.data?.categorysettings?.masterPackage);
                setHeroSliderImages(
                    response.data?.categorysettings?.heroSliderImages ?? { 0: "" }
                );
                setCustomFields(
                    response?.data?.customfields || [{ key: "", value: "" }]
                );
            })
            .catch((error) => {
                if (error?.message === "Network Error") {
                    NetworkErrorAlert(error?.message);
                } else {
                    toast.error(error?.response?.data, {
                        autoClose: 3000,
                        hideProgressBar: true,
                        draggable: true,
                    });
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        populateList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleNext = () => {
        setStep((prevStep) => prevStep + 1);
    };

    const handlePrevious = () => {
        setStep((prevStep) => prevStep - 1);
    };



    const handleFieldChange = (fieldName, fieldValue) => {
        setDomainFormData((prevState) => ({
            ...prevState,
            [fieldName]: fieldValue,
        }));
    };

    const handleSiteConfigChange = (fieldName, fieldValue) => {
        setSiteConfigData((prevState) => ({
            ...prevState,
            [fieldName]: fieldValue,
        }));
    };



    const addField = () => {
        setUserRole((prevRole) => [...prevRole, { key: "", value: "" }]);
    };

    const removeField = (index) => {
        setUserRole((prevRole) => prevRole.filter((_, i) => i !== index));
    };


    const updateUserRoles = (index, updatedRole) => {
        setUserRole((prevRole) => {
            const newRole = [...prevRole];
            newRole[index] = updatedRole;
            return newRole;
        });
    };


    const handleSubmit = (event) => {
        event?.preventDefault();
        setReqLoading(true);

        const data = {
            ...reqDomainData,
            id: reqDomainData.id,
            type: reqDomainData.type,
            userId: reqDomainData.userId,
            appsettings: domainFormData,
            siteconfig: siteConfigData,
            customfields: customFields,
            categorysettings: {
                ...reqDomainData.categorysettings,
                countryTypes: countryTypes,
                courseNames: courseNames,
                subcategoryTypes: subcategoryTypes,
                paymentTypes: paymentTypes,
                teachingModes: teachingModes,
                starRatings: starRatings,
                basicPackage: basicPackage,
                proPackage: proPackage,
                masterPackage: masterPackage,
                heroSliderImages: heroSliderImages,
            },
            appsettingsarrayobjects: {
                ...reqDomainData?.categorysettings?.appsettingsarrayobjects,
                navItems: navItems,
                userRole: userRole,
                dynamicUrls: dynamicUrls,

            },
        };

        axios
            .put(`${Actions.BASE_URL}configs/${reqDomainData.id}`, data)
            .then((response) => {
                successAlert();
                populateList();
                // window.location.reload();
            })
            .catch((error) => {
                if (error?.message === "Network Error") {
                    NetworkErrorAlert(error?.message);
                } else {
                    toast.error(error?.response?.data, {
                        autoClose: 3000,
                        hideProgressBar: true,
                        draggable: true,
                    });
                }
            })
            .finally(() => setReqLoading(false));
    };

    const successAlert = (param) => {
        confirmAlert({
            customUI: () => {
                return (
                    <div className="custom-ui d-flex align-items-center justify-content-center flex-column bg-white p-4 rounded-1">
                        <Check2Circle className="display-1 theme-text" />
                        <h5 className="theme-text-2 text-center my-2 text-capitalize">
                            Data Updated successfully
                        </h5>
                    </div>
                );
            },
        });
    };



    return (
        <>

            {reqLoading ? (
                <>
                    <span className="register-loader"> </span>
                    <span className="spinnerOverlay"></span>
                </>
            ) : null}

            {loading ? (
                <CustomLoader />
            ) : (
                <Container fluid="lg" className="formMain create my-3 mt-0 pt-0 demosEditSection">

                    <div className="demosAddFormHeader text-center">
                        <h2 className="addFormHeading text-capitalize">
                            Admin Configuration
                        </h2>
                        <div className="demosEditHeader text-center mb-3 mb-lg-5">
                            <ul className="demosEditList">
                                <li>
                                    <button
                                        className={
                                            step === 1 ? "active stepstriggers" : "stepstriggers"
                                        }
                                        onClick={() => setStep(1)}
                                    >
                                        <span>1</span>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        className={
                                            step === 2 ? "active stepstriggers" : "stepstriggers"
                                        }
                                        onClick={() => setStep(2)}
                                    >
                                        <span>2</span>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        className={
                                            step === 3 ? "active stepstriggers" : "stepstriggers"
                                        }
                                        onClick={() => setStep(3)}
                                    >
                                        <span>3</span>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        className={
                                            step === 4 ? "active stepstriggers" : "stepstriggers"
                                        }
                                        onClick={() => setStep(4)}
                                    >
                                        <span>4</span>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        className={
                                            step === 5 ? "active stepstriggers" : "stepstriggers"
                                        }
                                        onClick={() => setStep(5)}
                                    >
                                        <span>5</span>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        className={
                                            step === 6 ? "active stepstriggers" : "stepstriggers"
                                        }
                                        onClick={() => setStep(6)}
                                    >
                                        <span>6</span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>


                    <Form
                        id="add-demo-form"
                        onSubmit={handleSubmit}
                    >
                        {/* Step */}
                        {step === 1 && (
                            <Row>
                                <Col md={6}>
                                    <small className="text-end d-block text-dark">
                                        required<span className="text-danger">*</span>
                                    </small>
                                    <Form.Group className="form-floating mb-3">
                                        <Form.Control
                                            required
                                            type="text"
                                            id="name"
                                            name="name"
                                            placeholder="Type"
                                            value={siteConfigData?.name}
                                            onChange={(e) =>
                                                handleSiteConfigChange("name", e.target.value)
                                            }
                                        />
                                        <label htmlFor="name">Site Name</label>
                                    </Form.Group>
                                </Col>

                                <Col md={6}>
                                    <small className="text-end d-block text-dark">
                                        required<span className="text-danger">*</span>
                                    </small>
                                    <Form.Group className="form-floating mb-3">
                                        <Form.Control
                                            required
                                            type="text"
                                            id="title"
                                            name="title"
                                            placeholder="Type"
                                            value={siteConfigData?.title}
                                            onChange={(e) =>
                                                handleSiteConfigChange("title", e.target.value)
                                            }
                                        />
                                        <label htmlFor="title">Site Title</label>
                                    </Form.Group>
                                </Col>

                                <Col md={6}>
                                    <small className="text-end d-block text-dark">
                                        required<span className="text-danger">*</span>
                                    </small>
                                    <Form.Group className="form-floating mb-3">
                                        <Form.Control
                                            required
                                            type="text"
                                            id="host"
                                            host="host"
                                            placeholder="host"
                                            value={reqDomainData?.host}
                                            onChange={(e) => {
                                                setReqDomainData({
                                                    ...reqDomainData,
                                                    host: e.target.value,
                                                });
                                            }}
                                        />
                                        <label htmlFor="host">Host</label>
                                    </Form.Group>
                                </Col>

                                <Col md={6}>
                                    <small className="text-end d-block text-dark">
                                        required<span className="text-danger">*</span>
                                    </small>
                                    <Form.Group className="form-floating mb-3">
                                        <Form.Control
                                            required
                                            type="text"
                                            id="adminEmail"
                                            name="adminEmail"
                                            placeholder="adminEmail"
                                            value={siteConfigData?.adminEmail}
                                            onChange={(e) =>
                                                handleSiteConfigChange("adminEmail", e.target.value)
                                            }
                                        />
                                        <label htmlFor="adminEmail">Admin Email</label>
                                    </Form.Group>
                                </Col>

                                <Col md={6}>
                                    <small className="text-end d-block text-dark">
                                        required<span className="text-danger">*</span>
                                    </small>
                                    <Form.Group className="form-floating mb-3">
                                        <Form.Control
                                            required
                                            type="text"
                                            id="supportEmail"
                                            name="supportEmail"
                                            placeholder="supportEmail"
                                            value={siteConfigData?.supportEmail}
                                            onChange={(e) =>
                                                handleSiteConfigChange("supportEmail", e.target.value)
                                            }
                                        />
                                        <label htmlFor="supportEmail">Support Email</label>
                                    </Form.Group>
                                </Col>

                                <Col md={6}>
                                    <small className="text-end d-block text-dark">
                                        required<span className="text-danger">*</span>
                                    </small>
                                    <Form.Group className="form-floating mb-3">
                                        <Form.Control
                                            required
                                            type="text"
                                            id="fromEmail"
                                            name="fromEmail"
                                            placeholder="fromEmail"
                                            value={siteConfigData?.fromEmail}
                                            onChange={(e) =>
                                                handleSiteConfigChange("fromEmail", e.target.value)
                                            }
                                        />
                                        <label htmlFor="fromEmail">
                                            Add Email to send mails from
                                        </label>
                                    </Form.Group>
                                </Col>


                                <Col md={12}>
                                    <Form.Group className="form-floating mb-3">
                                        <Form.Control
                                            as={"textarea"}
                                            rows={4}
                                            style={{
                                                height: "auto",
                                            }}
                                            id="description"
                                            name="description"
                                            placeholder="description"
                                            value={siteConfigData?.description}
                                            onChange={(e) =>
                                                handleSiteConfigChange("description", e.target.value)
                                            }
                                        />
                                        <label htmlFor="description">
                                            Enter your description
                                        </label>
                                    </Form.Group>
                                </Col>

                                <Col md={12} className="mt-3 d-flex justify-content-end gap-2">


                                    <Button
                                        type="submit"
                                        className="demosEditBtn "
                                    >
                                        Submit
                                    </Button>
                                    <Button
                                        type="button"
                                        className="demosEditBtn "
                                        onClick={handleNext}
                                    >
                                        Next
                                    </Button>
                                </Col>

                            </Row>
                        )}
                        {/* Step */}
                        {step === 2 && (
                            <Row className="mx-0">


                                <Col md={12}>
                                    <small className="text-end d-block text-dark">
                                        required<span className="text-danger">*</span>
                                    </small>
                                    <Form.Group className="form-floating mb-3">
                                        <Form.Control
                                            required
                                            type="text"
                                            id="logo"
                                            name="logo"
                                            placeholder="Logo Url"
                                            value={domainFormData?.logo}
                                            onChange={(e) =>
                                                handleFieldChange("logo", e.target.value)
                                            }
                                        />
                                        <label htmlFor="logo">Logo Url</label>
                                    </Form.Group>
                                </Col>

                                <Col md={12}>
                                    <Form.Group className="form-floating mb-3">
                                        <Form.Control
                                            type="text"
                                            id="favicon"
                                            name="favicon"
                                            placeholder="FavIcon URL"
                                            value={domainFormData?.favicon}
                                            onChange={(e) =>
                                                handleFieldChange("favicon", e.target.value)
                                            }
                                        />
                                        <label htmlFor="favicon">FavIcon URL</label>
                                    </Form.Group>
                                </Col>

                                <Col md={12}>
                                    <Form.Group className="form-floating mb-3">
                                        <Form.Control
                                            as={"textarea"}
                                            rows={4}
                                            style={{
                                                height: "auto",
                                            }}
                                            id="keywords"
                                            name="keywords"
                                            placeholder="Keywords/Tags"
                                            value={domainFormData?.keywords}
                                            onChange={(e) =>
                                                handleFieldChange("keywords", e.target.value)
                                            }
                                        />
                                        <label htmlFor="keywords">
                                            Enter your Keywords/Tags separated by a comma ","
                                        </label>
                                    </Form.Group>
                                </Col>

                                <Col md={12}>
                                    <Form.Group className="form-floating mb-3">
                                        <Form.Control
                                            type="text"
                                            id="heroSliderHeading"
                                            name="heroSliderHeading"
                                            placeholder="Hero Slider Heading"
                                            value={domainFormData?.heroSliderHeading}
                                            onChange={(e) =>
                                                handleFieldChange(
                                                    "heroSliderHeading",
                                                    e.target.value
                                                )
                                            }
                                        />
                                        <label htmlFor="heroSliderHeading">
                                            Hero Slider Heading
                                        </label>
                                    </Form.Group>
                                </Col>

                                <Col md={12}>
                                    <Form.Group className="form-floating mb-3">
                                        <Form.Control
                                            type="text"
                                            id="heroSliderText"
                                            name="heroSliderText"
                                            placeholder="Hero Slider Description"
                                            value={domainFormData?.heroSliderText}
                                            onChange={(e) =>
                                                handleFieldChange("heroSliderText", e.target.value)
                                            }
                                        />
                                        <label htmlFor="heroSliderText">
                                            Hero Slider Description
                                        </label>
                                    </Form.Group>
                                </Col>


                                <Col md={6}>
                                    <small className="text-end d-block text-dark">
                                        required<span className="text-danger">*</span>
                                    </small>
                                    <Form.Group className="form-floating mb-3">
                                        <Form.Control
                                            required
                                            type="text"
                                            id="trainersListID"
                                            name="trainersListID"
                                            placeholder="trainersListID"
                                            value={domainFormData?.trainersListID}
                                            onChange={(e) =>
                                                handleFieldChange("trainersListID", e.target.value)
                                            }
                                        />
                                        <label htmlFor="trainersListID">Trainers List ID</label>
                                    </Form.Group>
                                </Col>

                                <Col md={6}>
                                    <small className="text-end d-block text-dark">
                                        required<span className="text-danger">*</span>
                                    </small>
                                    <Form.Group className="form-floating mb-3">
                                        <Form.Control
                                            required
                                            type="text"
                                            id="subscriptionListID"
                                            name="subscriptionListID"
                                            placeholder="subscriptionListID"
                                            value={domainFormData?.subscriptionListID}
                                            onChange={(e) =>
                                                handleFieldChange(
                                                    "subscriptionListID",
                                                    e.target.value
                                                )
                                            }
                                        />
                                        <label htmlFor="subscriptionListID">
                                            Subscription List ID
                                        </label>
                                    </Form.Group>
                                </Col>


                                {/* ALiases */}
                                <Accordion>
                                    <Accordion.Item eventKey="0" className="border-0">
                                        <Accordion.Header className="theme-text-2 border-bottom">
                                            <h5 className="theme-text-2 mb-0">Name Aliases</h5>
                                        </Accordion.Header>
                                        <Accordion.Body className="">
                                            <Row>
                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="demoAlias"
                                                            name="demoAlias"
                                                            placeholder="demoAlias"
                                                            value={domainFormData?.demoAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("demoAlias", e.target.value)
                                                            }
                                                        />
                                                        <label htmlFor="demoAlias" className="text-capitalize">
                                                            demo Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="demosAlias"
                                                            name="demosAlias"
                                                            placeholder="demosAlias"
                                                            value={domainFormData?.demosAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("demosAlias", e.target.value)
                                                            }
                                                        />
                                                        <label htmlFor="demosAlias" className="text-capitalize">
                                                            demos Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="eventAlias"
                                                            name="eventAlias"
                                                            placeholder="eventAlias"
                                                            value={domainFormData?.eventAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("eventAlias", e.target.value)
                                                            }
                                                        />
                                                        <label htmlFor="eventAlias" className="text-capitalize">
                                                            event Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="eventsAlias"
                                                            name="eventsAlias"
                                                            placeholder="eventsAlias"
                                                            value={domainFormData?.eventsAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("eventsAlias", e.target.value)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="eventsAlias"
                                                            className="text-capitalize"
                                                        >
                                                            events Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="courseAlias"
                                                            name="courseAlias"
                                                            placeholder="courseAlias"
                                                            value={domainFormData?.courseAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("courseAlias", e.target.value)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="courseAlias"
                                                            className="text-capitalize"
                                                        >
                                                            course Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="coursesAlias"
                                                            name="coursesAlias"
                                                            placeholder="coursesAlias"
                                                            value={domainFormData?.coursesAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("coursesAlias", e.target.value)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="coursesAlias"
                                                            className="text-capitalize"
                                                        >
                                                            courses Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="blogAlias"
                                                            name="blogAlias"
                                                            placeholder="blogAlias"
                                                            value={domainFormData?.blogAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("blogAlias", e.target.value)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="blogAlias"
                                                            className="text-capitalize"
                                                        >
                                                            blog Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="blogsAlias"
                                                            name="blogsAlias"
                                                            placeholder="blogsAlias"
                                                            value={domainFormData?.blogsAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("blogsAlias", e.target.value)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="blogsAlias"
                                                            className="text-capitalize"
                                                        >
                                                            blogs Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="trainerAlias"
                                                            name="trainerAlias"
                                                            placeholder="trainerAlias"
                                                            value={domainFormData?.trainerAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("trainerAlias", e.target.value)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="trainerAlias"
                                                            className="text-capitalize"
                                                        >
                                                            trainer Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="trainersAlias"
                                                            name="trainersAlias"
                                                            placeholder="trainersAlias"
                                                            value={domainFormData?.trainersAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("trainersAlias", e.target.value)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="trainersAlias"
                                                            className="text-capitalize"
                                                        >
                                                            trainers Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="startupAlias"
                                                            name="startupAlias"
                                                            placeholder="startupAlias"
                                                            value={domainFormData?.startupAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("startupAlias", e.target.value)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="startupAlias"
                                                            className="text-capitalize"
                                                        >
                                                            startup Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="startupsAlias"
                                                            name="startupsAlias"
                                                            placeholder="startupsAlias"
                                                            value={domainFormData?.startupsAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("startupsAlias", e.target.value)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="startupsAlias"
                                                            className="text-capitalize"
                                                        >
                                                            startups Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="speakerAlias"
                                                            name="speakerAlias"
                                                            placeholder="speakerAlias"
                                                            value={domainFormData?.speakerAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("speakerAlias", e.target.value)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="speakerAlias"
                                                            className="text-capitalize"
                                                        >
                                                            speaker Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="speakersAlias"
                                                            name="speakersAlias"
                                                            placeholder="speakersAlias"
                                                            value={domainFormData?.speakersAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("speakersAlias", e.target.value)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="speakersAlias"
                                                            className="text-capitalize"
                                                        >
                                                            speakers Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>
                                                {/* ============ */}
                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="mentorAlias"
                                                            name="mentorAlias"
                                                            placeholder="mentorAlias"
                                                            value={domainFormData?.mentorAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("mentorAlias", e.target.value)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="mentorAlias"
                                                            className="text-capitalize"
                                                        >
                                                            mentor Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="mentorsAlias"
                                                            name="mentorsAlias"
                                                            placeholder="mentorsAlias"
                                                            value={domainFormData?.mentorsAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("mentorsAlias", e.target.value)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="mentorsAlias"
                                                            className="text-capitalize"
                                                        >
                                                            mentors Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>
                                                {/* ============ */}
                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="meetupAlias"
                                                            name="meetupAlias"
                                                            placeholder="meetupAlias"
                                                            value={domainFormData?.meetupAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("meetupAlias", e.target.value)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="meetupAlias"
                                                            className="text-capitalize"
                                                        >
                                                            meetup Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="meetupsAlias"
                                                            name="meetupsAlias"
                                                            placeholder="meetupsAlias"
                                                            value={domainFormData?.meetupsAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("meetupsAlias", e.target.value)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="meetupsAlias"
                                                            className="text-capitalize"
                                                        >
                                                            meetups Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>
                                                {/* ============ */}
                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="founderAlias"
                                                            name="founderAlias"
                                                            placeholder="founderAlias"
                                                            value={domainFormData?.founderAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("founderAlias", e.target.value)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="founderAlias"
                                                            className="text-capitalize"
                                                        >
                                                            founder Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="foundersAlias"
                                                            name="foundersAlias"
                                                            placeholder="foundersAlias"
                                                            value={domainFormData?.foundersAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("foundersAlias", e.target.value)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="foundersAlias"
                                                            className="text-capitalize"
                                                        >
                                                            founders Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>
                                                {/* ============ */}
                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="bloggerAlias"
                                                            name="bloggerAlias"
                                                            placeholder="bloggerAlias"
                                                            value={domainFormData?.bloggerAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("bloggerAlias", e.target.value)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="bloggerAlias"
                                                            className="text-capitalize"
                                                        >
                                                            blogger Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="bloggersAlias"
                                                            name="bloggersAlias"
                                                            placeholder="bloggersAlias"
                                                            value={domainFormData?.bloggersAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("bloggersAlias", e.target.value)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="bloggersAlias"
                                                            className="text-capitalize"
                                                        >
                                                            bloggers Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>
                                                {/* ============ */}
                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="internAlias"
                                                            name="internAlias"
                                                            placeholder="internAlias"
                                                            value={domainFormData?.internAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("internAlias", e.target.value)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="internAlias"
                                                            className="text-capitalize"
                                                        >
                                                            intern Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="internsAlias"
                                                            name="internsAlias"
                                                            placeholder="internsAlias"
                                                            value={domainFormData?.internsAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("internsAlias", e.target.value)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="internsAlias"
                                                            className="text-capitalize"
                                                        >
                                                            interns Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>
                                                {/* ============ */}
                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="freelancerAlias"
                                                            name="freelancerAlias"
                                                            placeholder="freelancerAlias"
                                                            value={domainFormData?.freelancerAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("freelancerAlias", e.target.value)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="freelancerAlias"
                                                            className="text-capitalize"
                                                        >
                                                            freelancer Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="freelancersAlias"
                                                            name="freelancersAlias"
                                                            placeholder="freelancersAlias"
                                                            value={domainFormData?.freelancersAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange(
                                                                    "freelancersAlias",
                                                                    e.target.value
                                                                )
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="freelancersAlias"
                                                            className="text-capitalize"
                                                        >
                                                            freelancers Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>
                                                {/* ============ */}
                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="serviceProviderAlias"
                                                            name="serviceProviderAlias"
                                                            placeholder="serviceProviderAlias"
                                                            value={domainFormData?.serviceProviderAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange(
                                                                    "serviceProviderAlias",
                                                                    e.target.value
                                                                )
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="serviceProviderAlias"
                                                            className="text-capitalize"
                                                        >
                                                            serviceProvider Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="serviceProvidersAlias"
                                                            name="serviceProvidersAlias"
                                                            placeholder="serviceProvidersAlias"
                                                            value={domainFormData?.serviceProvidersAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange(
                                                                    "serviceProvidersAlias",
                                                                    e.target.value
                                                                )
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="serviceProvidersAlias"
                                                            className="text-capitalize"
                                                        >
                                                            serviceProviders Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>
                                                {/* ============ */}
                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="partnerAlias"
                                                            name="partnerAlias"
                                                            placeholder="partnerAlias"
                                                            value={domainFormData?.partnerAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("partnerAlias", e.target.value)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="partnerAlias"
                                                            className="text-capitalize"
                                                        >
                                                            partner Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="partnersAlias"
                                                            name="partnersAlias"
                                                            placeholder="partnersAlias"
                                                            value={domainFormData?.partnersAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("partnersAlias", e.target.value)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="partnersAlias"
                                                            className="text-capitalize"
                                                        >
                                                            partners Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>
                                                {/* ============ */}
                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="sponsorAlias"
                                                            name="sponsorAlias"
                                                            placeholder="sponsorAlias"
                                                            value={domainFormData?.sponsorAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("sponsorAlias", e.target.value)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="sponsorAlias"
                                                            className="text-capitalize"
                                                        >
                                                            sponsor Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="sponsorsAlias"
                                                            name="sponsorsAlias"
                                                            placeholder="sponsorsAlias"
                                                            value={domainFormData?.sponsorsAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("sponsorsAlias", e.target.value)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="sponsorsAlias"
                                                            className="text-capitalize"
                                                        >
                                                            sponsors Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>
                                                {/* ============ */}
                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="corporateStartupAlias"
                                                            name="corporateStartupAlias"
                                                            placeholder="corporateStartupAlias"
                                                            value={domainFormData?.corporateStartupAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange(
                                                                    "corporateStartupAlias",
                                                                    e.target.value
                                                                )
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="corporateStartupAlias"
                                                            className="text-capitalize"
                                                        >
                                                            corporateStartup Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="corporateStartupsAlias"
                                                            name="corporateStartupsAlias"
                                                            placeholder="corporateStartupsAlias"
                                                            value={domainFormData?.corporateStartupsAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange(
                                                                    "corporateStartupsAlias",
                                                                    e.target.value
                                                                )
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="corporateStartupsAlias"
                                                            className="text-capitalize"
                                                        >
                                                            corporateStartups Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>
                                                {/* ============ */}
                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="NRIStartupAlias"
                                                            name="NRIStartupAlias"
                                                            placeholder="NRIStartupAlias"
                                                            value={domainFormData?.NRIStartupAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("NRIStartupAlias", e.target.value)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="NRIStartupAlias"
                                                            className="text-capitalize"
                                                        >
                                                            NRIStartup Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="NRIStartupsAlias"
                                                            name="NRIStartupsAlias"
                                                            placeholder="NRIStartupsAlias"
                                                            value={domainFormData?.NRIStartupsAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange(
                                                                    "NRIStartupsAlias",
                                                                    e.target.value
                                                                )
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="NRIStartupsAlias"
                                                            className="text-capitalize"
                                                        >
                                                            NRIStartups Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>
                                                {/* ============ */}
                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="betaTesterAlias"
                                                            name="betaTesterAlias"
                                                            placeholder="betaTesterAlias"
                                                            value={domainFormData?.betaTesterAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("betaTesterAlias", e.target.value)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="betaTesterAlias"
                                                            className="text-capitalize"
                                                        >
                                                            betaTester Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="betaTestersAlias"
                                                            name="betaTestersAlias"
                                                            placeholder="betaTestersAlias"
                                                            value={domainFormData?.betaTestersAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange(
                                                                    "betaTestersAlias",
                                                                    e.target.value
                                                                )
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="betaTestersAlias"
                                                            className="text-capitalize"
                                                        >
                                                            betaTesters Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>
                                                {/* ---- */}
                                                {/* ============ */}
                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="propertyEventAlias"
                                                            name="propertyEventAlias"
                                                            placeholder="propertyEventAlias"
                                                            value={domainFormData?.propertyEventAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange(
                                                                    "propertyEventAlias",
                                                                    e.target.value
                                                                )
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="propertyEventAlias"
                                                            className="text-capitalize"
                                                        >
                                                            propertyEvent Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="propertyEventsAlias"
                                                            name="propertyEventsAlias"
                                                            placeholder="propertyEventsAlias"
                                                            value={domainFormData?.propertyEventsAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange(
                                                                    "propertyEventsAlias",
                                                                    e.target.value
                                                                )
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="propertyEventsAlias"
                                                            className="text-capitalize"
                                                        >
                                                            propertyEvents Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>
                                                {/* ---- */}
                                                {/* ============ */}
                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="propertyAlias"
                                                            name="propertyAlias"
                                                            placeholder="propertyAlias"
                                                            value={domainFormData?.propertyAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("propertyAlias", e.target.value)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="propertyAlias"
                                                            className="text-capitalize"
                                                        >
                                                            property Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="propertiesAlias"
                                                            name="propertiesAlias"
                                                            placeholder="propertiesAlias"
                                                            value={domainFormData?.propertiesAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("propertiesAlias", e.target.value)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="propertiesAlias"
                                                            className="text-capitalize"
                                                        >
                                                            properties Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>
                                                {/* ---- */}
                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="cohortAlias"
                                                            name="cohortAlias"
                                                            placeholder="cohortAlias"
                                                            value={domainFormData?.cohortAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("cohortAlias", e.target.value)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="cohortAlias"
                                                            className="text-capitalize"
                                                        >
                                                            cohort Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="cohortsAlias"
                                                            name="cohortsAlias"
                                                            placeholder="cohortsAlias"
                                                            value={domainFormData?.cohortsAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("cohortsAlias", e.target.value)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="cohortsAlias"
                                                            className="text-capitalize"
                                                        >
                                                            cohorts Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>
                                                {/* ---- */}
                                                {/* ---- */}
                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="pageAlias"
                                                            name="pageAlias"
                                                            placeholder="pageAlias"
                                                            value={domainFormData?.pageAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("pageAlias", e.target.value)
                                                            }
                                                        />
                                                        <label htmlFor="pageAlias" className="text-capitalize">
                                                            page Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="pagesAlias"
                                                            name="pagesAlias"
                                                            placeholder="pagesAlias"
                                                            value={domainFormData?.pagesAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("pagesAlias", e.target.value)
                                                            }
                                                        />
                                                        <label htmlFor="pagesAlias" className="text-capitalize">
                                                            pages Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>
                                                {/* ---- */}

                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="codeAIAlias"
                                                            name="codeAIAlias"
                                                            placeholder="codeAIAlias"
                                                            value={domainFormData?.codeAIAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("codeAIAlias", e.target.value)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="codeAIAlias"
                                                            className="text-capitalize"
                                                        >
                                                            codeAI Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="myPageAlias"
                                                            name="myPageAlias"
                                                            placeholder="myPageAlias"
                                                            value={domainFormData?.myPageAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("myPageAlias", e.target.value)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="myPageAlias"
                                                            className="text-capitalize"
                                                        >
                                                            myPage Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="instructorAlias"
                                                            name="instructorAlias"
                                                            placeholder="instructorAlias"
                                                            value={domainFormData?.instructorAlias}
                                                            onChange={(e) =>
                                                                handleFieldChange("instructorAlias", e.target.value)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="instructorAlias"
                                                            className="text-capitalize h5"
                                                        >
                                                            instructor Alias
                                                        </label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="userReqBtnText"
                                                            name="userReqBtnText"
                                                            placeholder="User Request Button Text"
                                                            value={domainFormData?.userReqBtnText}
                                                            onChange={(e) =>
                                                                handleFieldChange("userReqBtnText", e.target.value)
                                                            }
                                                        />
                                                        <label htmlFor="userReqBtnText">
                                                            User Request Button Text
                                                        </label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="codeGeneratorText"
                                                            name="codeGeneratorText"
                                                            placeholder="User Request Button Text"
                                                            value={domainFormData?.codeGeneratorText}
                                                            onChange={(e) =>
                                                                handleFieldChange(
                                                                    "codeGeneratorText",
                                                                    e.target.value
                                                                )
                                                            }
                                                        />
                                                        <label htmlFor="codeGeneratorText">
                                                            Code Generator Text
                                                        </label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="codeGeneratorDesc"
                                                            name="codeGeneratorDesc"
                                                            placeholder="User Request Button Text"
                                                            value={domainFormData?.codeGeneratorDesc}
                                                            onChange={(e) =>
                                                                handleFieldChange(
                                                                    "codeGeneratorDesc",
                                                                    e.target.value
                                                                )
                                                            }
                                                        />
                                                        <label htmlFor="codeGeneratorDesc">
                                                            Code Generator Description
                                                        </label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="form-floating mb-3">
                                                        <Form.Control

                                                            type="text"
                                                            id="codeGeneratorPlaceholder"
                                                            name="codeGeneratorPlaceholder"
                                                            placeholder="User Request Button Text"
                                                            value={domainFormData?.codeGeneratorPlaceholder}
                                                            onChange={(e) =>
                                                                handleFieldChange(
                                                                    "codeGeneratorPlaceholder",
                                                                    e.target.value
                                                                )
                                                            }
                                                        />
                                                        <label htmlFor="codeGeneratorPlaceholder">
                                                            Code Generator Description
                                                        </label>
                                                    </Form.Group>
                                                </Col>

                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>

                                <Col md={12} className="mt-3">

                                    <Button
                                        type="button"
                                        className="prev demosEditBtn"
                                        onClick={handlePrevious}
                                    >
                                        Prev
                                    </Button>

                                    <Button
                                        type="button"
                                        className="next demosEditBtn "
                                        onClick={handleNext}
                                    >
                                        Next
                                    </Button>
                                </Col>
                            </Row>
                        )}
                        {/* Step */}
                        {step === 3 && (
                            <Row className="mx-0">
                                {/* Default HomePage Path */}
                                <h6 className="theme-text-2 fw-normal mt-3">
                                    Default HomePage
                                </h6>
                                <hr />

                                <Col md={12}>
                                    <small className="text-end d-block text-dark">
                                        required<span className="text-danger">*</span>
                                    </small>
                                    <Form.Group className="form-floating mb-3">
                                        <Form.Select
                                            id="homePageRoute"
                                            className={`form-control form-select text-capitalize`}
                                            value={domainFormData?.homePageRoute}
                                            name="homePageRoute"
                                            onChange={(e) =>
                                                handleFieldChange("homePageRoute", e.target.value)
                                            }
                                        >
                                            <option value="">Select Default Homepage</option>
                                            <option value="/">Default Homepage Page</option>
                                            <option value="/courses">{coursesAlias} Page</option>
                                            <option value="/demos">{demosAlias} Page</option>
                                            <option value="/startup/itemss">
                                                {startupsAlias} Page
                                            </option>
                                            <option value="/contact">Contact Page</option>
                                            <option value="/blogs">Blogs Page</option>
                                            <option value="/code-ai">{codeAIAlias}</option>
                                            <option value="/cohort/itemss">
                                                {cohortsAlias} Page
                                            </option>
                                            <option value="/propertyevent/items">
                                                {propertyEventsAlias} Page
                                            </option>
                                            <option value="/events">{eventsAlias} Page</option>
                                        </Form.Select>
                                        <Form.Label
                                            className="form-label"
                                            htmlFor="defaultRegisterForm"
                                        >
                                            Default Home Page
                                        </Form.Label>
                                    </Form.Group>
                                </Col>
                                {/* default register form */}
                                <h6 className="theme-text-2 fw-normal mt-4">
                                    Default Register {demosAlias}/{coursesAlias} Form
                                </h6>
                                <hr />
                                <Col md={12}>
                                    <small className="text-end d-block text-dark">
                                        required<span className="text-danger">*</span>
                                    </small>
                                    <Form.Group className="form-floating mb-3">
                                        <Form.Select
                                            id="defaultRegisterForm"
                                            className={`form-control form-select text-capitalize`}
                                            value={domainFormData?.defaultRegisterForm}
                                            name="defaultRegisterForm"
                                            onChange={(e) =>
                                                handleFieldChange(
                                                    "defaultRegisterForm",
                                                    e.target.value
                                                )
                                            }
                                        >
                                            <option value="">Select Default Register Form</option>
                                            <option value="registerPopup">Register Popup</option>
                                            <option value="registerAcceleratorForm">
                                                Register Accelerator Form
                                            </option>
                                        </Form.Select>
                                        <Form.Label
                                            className="form-label"
                                            htmlFor="defaultRegisterForm"
                                        >
                                            Default Register Form
                                        </Form.Label>
                                    </Form.Group>
                                </Col>
                                {/* heroSliderImages */}
                                <Col
                                    md={12}
                                    className="d-flex justify-content-between gap-3 mt-4 mb-2"
                                >
                                    <h6 className="theme-text-2 flex-grow-1">
                                        Hero Slider Images
                                    </h6>
                                    <Button
                                        className="clickable add-button ms-auto rounded-circle d-flex"
                                        onClick={() => {
                                            setHeroSliderImages((prevState) => {
                                                const newIndex =
                                                    Object.keys(prevState)?.length.toString();
                                                return {
                                                    ...prevState,
                                                    [newIndex]: "",
                                                };
                                            });
                                        }}
                                    >
                                        <span className="addBttn d-block">+</span>
                                    </Button>
                                </Col>
                                <hr />
                                <Col md={12} className="mt-3">
                                    <Row>
                                        {heroSliderImages &&
                                            Object.keys(heroSliderImages)?.map((index) => (
                                                <DynamicOptionsFieldset
                                                    ColNum={12}
                                                    label={"Slider Image"}
                                                    key={index}
                                                    index={index}
                                                    fields={heroSliderImages}
                                                    setFields={setHeroSliderImages}
                                                />
                                            ))}
                                    </Row>
                                </Col>

                                <Col md={12} className="mt-3">

                                    <Button
                                        type="button"
                                        className="prev demosEditBtn"
                                        onClick={handlePrevious}
                                    >
                                        Prev
                                    </Button>

                                    <Button
                                        type="button"
                                        className="next demosEditBtn "
                                        onClick={handleNext}
                                    >
                                        Next
                                    </Button>
                                </Col>

                            </Row>
                        )}
                        {/* Step */}
                        {step === 4 && (
                            <>
                                <Row className="mx-0">
                                    <Col
                                        md={12}
                                        className="d-flex justify-content-between align-items-center gap-3 mt-4 mb-2"
                                    >
                                        <h6 className="theme-text-2 flex-grow-1 text-capitalize">
                                            Navbar Items
                                        </h6>
                                        <Button
                                            className="clickable add-button ms-auto rounded-circle d-flex"
                                            onClick={() =>
                                                setNavItems((prevNavItems) => [
                                                    ...prevNavItems,
                                                    { path: "/", label: "" },
                                                ])
                                            }
                                        >
                                            <span className="addBttn d-block">+</span>
                                        </Button>
                                    </Col>
                                    <hr />
                                    <Col md={12} className="mt-3">
                                        <Row>
                                            {navItems.map((navItem, index) => (
                                                <DynamicNavItemFieldset
                                                    key={index}
                                                    index={index}
                                                    fields={navItems}
                                                    setFields={setNavItems}
                                                />
                                            ))}
                                        </Row>
                                    </Col>
                                </Row>
                                <Accordion>
                                    <Accordion.Item eventKey="0" className="border-0">
                                        <Accordion.Header className="theme-text-2 border-bottom">
                                            <h5 className="theme-text-2">Items' details</h5>
                                        </Accordion.Header>
                                        <Accordion.Body className="px-0">
                                            <Row className="mx-0">
                                                <Col lg={4} md={6} className="mb-1 text-capitalize">
                                                    <span className="theme-text-2 italicInput text-lowercase">/demos</span> <ArrowRight /> {demosAlias}
                                                </Col>
                                                <Col lg={4} md={6} className="mb-1 text-capitalize">
                                                    <span className="theme-text-2 italicInput text-lowercase">/courses</span> <ArrowRight /> {coursesAlias}
                                                </Col>
                                                <Col lg={4} md={6} className="mb-1 text-capitalize">
                                                    <span className="theme-text-2 italicInput text-lowercase">/events</span> <ArrowRight /> {eventsAlias}
                                                </Col>
                                                <Col lg={4} md={6} className="mb-1 text-capitalize">
                                                    <span className="theme-text-2 italicInput text-lowercase">/trainers</span> <ArrowRight /> {trainersAlias}
                                                </Col>
                                                <Col lg={4} md={6} className="mb-1 text-capitalize">
                                                    <span className="theme-text-2 italicInput text-lowercase">/contact</span> <ArrowRight /> Contact
                                                </Col>
                                                <Col lg={4} md={6} className="mb-1 text-capitalize">
                                                    <span className="theme-text-2 italicInput text-lowercase">/propertyevent/items</span> <ArrowRight /> {propertyEventsAlias}{" "}
                                                </Col>
                                                <Col lg={4} md={6} className="mb-1 text-capitalize">
                                                    <span className="theme-text-2 italicInput text-lowercase">/code-ai</span> <ArrowRight /> {codeAIAlias}
                                                </Col>
                                                <Col lg={4} md={6} className="mb-1 text-capitalize">
                                                    <span className="theme-text-2 italicInput text-lowercase">/property/items</span> <ArrowRight /> {propertiesAlias}
                                                </Col>
                                                <Col lg={4} md={6} className="mb-1 text-capitalize">
                                                    <span className="theme-text-2 italicInput text-lowercase">/blog</span> <ArrowRight /> Blog
                                                </Col>
                                                <Col lg={4} md={6} className="mb-1 text-capitalize">
                                                    <span className="theme-text-2 italicInput text-lowercase">/corporates</span> <ArrowRight /> Corporates
                                                </Col>
                                                <Col lg={4} md={6} className="mb-1 text-capitalize">
                                                    <span className="theme-text-2 italicInput text-lowercase">/reviews</span> <ArrowRight /> Reviews
                                                </Col>
                                                <Col lg={4} md={6} className="mb-1 text-capitalize">
                                                    <span className="theme-text-2 italicInput text-lowercase">/startup/items</span> <ArrowRight /> {startupsAlias}
                                                </Col>
                                                <Col lg={4} md={6} className="mb-1 text-capitalize">
                                                    <span className="theme-text-2 italicInput text-lowercase">/cohort/items</span> <ArrowRight /> {cohortsAlias}
                                                </Col>
                                                <Col lg={4} md={6} className="mb-1 text-capitalize">
                                                    <span className="theme-text-2 italicInput text-lowercase">/items/meetup</span> <ArrowRight /> meetups
                                                </Col>
                                                <Col lg={4} md={6} className="mb-1 text-capitalize">
                                                    <span className="theme-text-2 italicInput text-lowercase">/founder/items</span> <ArrowRight /> founders
                                                </Col>
                                                <Col lg={4} md={6} className="mb-1 text-capitalize">
                                                    <span className="theme-text-2 italicInput text-lowercase">/speaker/items</span> <ArrowRight /> speakers
                                                </Col>
                                                <Col lg={4} md={6} className="mb-1 text-capitalize">
                                                    <span className="theme-text-2 italicInput text-lowercase">/mentor/items</span> <ArrowRight /> mentors
                                                </Col>
                                                <Col lg={4} md={6} className="mb-1 text-capitalize">
                                                    <span className="theme-text-2 italicInput text-lowercase">/items/blogger</span> <ArrowRight /> bloggers
                                                </Col>
                                                <Col lg={4} md={6} className="mb-1 text-capitalize">
                                                    <span className="theme-text-2 italicInput text-lowercase">/intern/items</span> <ArrowRight /> interns
                                                </Col>
                                                <Col lg={4} md={6} className="mb-1 text-capitalize">
                                                    <span className="theme-text-2 italicInput text-lowercase">/freelancer/items</span> <ArrowRight /> freelancers
                                                </Col>
                                                <Col lg={4} md={6} className="mb-1 text-capitalize">
                                                    <span className="theme-text-2 italicInput text-lowercase">/partner/items</span> <ArrowRight /> partners
                                                </Col>
                                                <Col lg={4} md={6} className="mb-1 text-capitalize">
                                                    <span className="theme-text-2 italicInput text-lowercase">/items/sponsor</span> <ArrowRight /> sponsors
                                                </Col>
                                                <Col lg={4} md={6} className="mb-1 text-capitalize">
                                                    <span className="theme-text-2 italicInput text-lowercase">/NRI-startup/items</span> <ArrowRight /> NRI startups
                                                </Col>
                                                <Col lg={4} md={6} className="mb-1 text-capitalize">
                                                    <span className="theme-text-2 italicInput text-lowercase">/items/bets-tester</span> <ArrowRight /> beta testers
                                                </Col>
                                                <Col md={6} className="mb-1 text-capitalize">
                                                    <span className="theme-text-2 italicInput text-lowercase">/items/corporate-startup</span> <ArrowRight /> corporate startups
                                                </Col>
                                                <Col md={6} className="mb-1 text-capitalize">
                                                    <span className="theme-text-2 italicInput text-lowercase">/service-provider/items</span> <ArrowRight /> service providers
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                                <div className="mt-3">

                                    <Button
                                        type="button"
                                        className="prev demosEditBtn"
                                        onClick={handlePrevious}
                                    >
                                        Prev
                                    </Button>

                                    <Button
                                        type="button"
                                        className="next demosEditBtn "
                                        onClick={handleNext}
                                    >
                                        Next
                                    </Button>
                                </div>

                            </>
                        )}
                        {/* Step */}
                        {step === 5 && (
                            <Row className="mx-0">
                                <h6 className="theme-text-2 fw-normal mt-3">
                                    Sidebar FiltersAlias
                                </h6>
                                <hr />
                                <Col md={6}>
                                    <small className="text-end d-block text-dark">
                                        required<span className="text-danger">*</span>
                                    </small>
                                    <Form.Group className="form-floating mb-3 text-capitalize">
                                        <Form.Control
                                            type="text"
                                            id="countryTypeFilterAlias"
                                            name="countryTypeFilterAlias"
                                            placeholder="countryTypeFilterAlias"
                                            value={domainFormData?.countryTypeFilterAlias}
                                            onChange={(e) =>
                                                handleFieldChange(
                                                    "countryTypeFilterAlias",
                                                    e.target.value
                                                )
                                            }
                                        />
                                        <label htmlFor="countryTypeFilterAlias">
                                            country_type FilterAlias
                                        </label>
                                    </Form.Group>
                                </Col>

                                <Col md={6}>
                                    <small className="text-end d-block text-dark">
                                        required<span className="text-danger">*</span>
                                    </small>
                                    <Form.Group className="form-floating mb-3 text-capitalize">
                                        <Form.Control
                                            type="text"
                                            id="coursesFilterAlias"
                                            name="coursesFilterAlias"
                                            placeholder="coursesFilterAlias"
                                            value={domainFormData?.coursesFilterAlias}
                                            onChange={(e) =>
                                                handleFieldChange(
                                                    "coursesFilterAlias",
                                                    e.target.value
                                                )
                                            }
                                        />
                                        <label htmlFor="coursesFilterAlias">
                                            category FilterAlias
                                        </label>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <small className="text-end d-block text-dark">
                                        required<span className="text-danger">*</span>
                                    </small>
                                    <Form.Group className="form-floating mb-3 text-capitalize">
                                        <Form.Control
                                            type="text"
                                            id="learningLevelFilterAlias"
                                            name="learningLevelFilterAlias"
                                            placeholder="learningLevelFilterAlias"
                                            value={domainFormData?.learningLevelFilterAlias}
                                            onChange={(e) =>
                                                handleFieldChange(
                                                    "learningLevelFilterAlias",
                                                    e.target.value
                                                )
                                            }
                                        />
                                        <label htmlFor="learningLevelFilterAlias">
                                            subcategory FilterAlias
                                        </label>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <small className="text-end d-block text-dark">
                                        required<span className="text-danger">*</span>
                                    </small>
                                    <Form.Group className="form-floating mb-3 text-capitalize">
                                        <Form.Control
                                            type="text"
                                            id="paymentFilterAlias"
                                            name="paymentFilterAlias"
                                            placeholder="paymentFilterAlias"
                                            value={domainFormData?.paymentFilterAlias}
                                            onChange={(e) =>
                                                handleFieldChange(
                                                    "paymentFilterAlias",
                                                    e.target.value
                                                )
                                            }
                                        />
                                        <label htmlFor="paymentFilterAlias">
                                            subcategorylevel1 FilterAlias
                                        </label>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <small className="text-end d-block text-dark">
                                        required<span className="text-danger">*</span>
                                    </small>
                                    <Form.Group className="form-floating mb-3 text-capitalize">
                                        <Form.Control
                                            type="text"
                                            id="teachingModesFilterAlias"
                                            name="teachingModesFilterAlias"
                                            placeholder="teachingModesFilterAlias"
                                            value={domainFormData?.teachingModesFilterAlias}
                                            onChange={(e) =>
                                                handleFieldChange(
                                                    "teachingModesFilterAlias",
                                                    e.target.value
                                                )
                                            }
                                        />
                                        <label htmlFor="teachingModesFilterAlias">
                                            subcategorylevel2 FilterAlias
                                        </label>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <small className="text-end d-block text-dark">
                                        required<span className="text-danger">*</span>
                                    </small>
                                    <Form.Group className="form-floating mb-3 text-capitalize">
                                        <Form.Control
                                            type="text"
                                            id="tagsFilterAlias"
                                            name="tagsFilterAlias"
                                            placeholder="tagsFilterAlias"
                                            value={domainFormData?.tagsFilterAlias}
                                            onChange={(e) =>
                                                handleFieldChange("tagsFilterAlias", e.target.value)
                                            }
                                        />
                                        <label htmlFor="tagsFilterAlias">
                                            subcategorylevel3 FilterAlias
                                        </label>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <small className="text-end d-block text-dark">
                                        required<span className="text-danger">*</span>
                                    </small>
                                    <Form.Group className="form-floating mb-3 text-capitalize">
                                        <Form.Control
                                            type="text"
                                            id="ratingsFilterAlias"
                                            name="ratingsFilterAlias"
                                            placeholder="ratingsFilterAlias"
                                            value={domainFormData?.ratingsFilterAlias}
                                            onChange={(e) =>
                                                handleFieldChange(
                                                    "ratingsFilterAlias",
                                                    e.target.value
                                                )
                                            }
                                        />
                                        <label htmlFor="ratingsFilterAlias">
                                            star_rating_type FilterAlias
                                        </label>
                                    </Form.Group>
                                </Col>

                                <>
                                    <h6 className="theme-text-2 fw-normal mt-3">
                                        Sidebar Filters
                                    </h6>
                                    <hr />

                                    <Col sm={6}>
                                        <Form.Check
                                            type="checkbox"
                                            className="custom-checkbox ps-0 mb-3"
                                            id="countryFilter"
                                            label={`country_type FilterAlias`}
                                            checked={domainFormData?.countryFilter === "show"}
                                            onChange={(e) => {
                                                const newValue = e.target.checked ? "show" : "hide";
                                                handleFieldChange("countryFilter", newValue);
                                            }}
                                        />
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Check
                                            type="checkbox"
                                            className="custom-checkbox ps-0 mb-3"
                                            id="coursesFilter"
                                            label={`category FilterAlias`}
                                            checked={domainFormData?.coursesFilter === "show"}
                                            onChange={(e) => {
                                                const newValue = e.target.checked ? "show" : "hide";
                                                handleFieldChange("coursesFilter", newValue);
                                            }}
                                        />
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Check
                                            type="checkbox"
                                            className="custom-checkbox ps-0 mb-3"
                                            id="learningLevelFilter"
                                            label={`subcategory FilterAlias`}
                                            checked={domainFormData?.learningLevelFilter === "show"}
                                            onChange={(e) => {
                                                const newValue = e.target.checked ? "show" : "hide";
                                                handleFieldChange("learningLevelFilter", newValue);
                                            }}
                                        />
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Check
                                            type="checkbox"
                                            className="custom-checkbox ps-0 mb-3"
                                            id="paymentFilter"
                                            label={`subcategorylevel1 FilterAlias`}
                                            checked={domainFormData?.paymentFilter === "show"}
                                            onChange={(e) => {
                                                const newValue = e.target.checked ? "show" : "hide";
                                                handleFieldChange("paymentFilter", newValue);
                                            }}
                                        />
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Check
                                            type="checkbox"
                                            className="custom-checkbox ps-0 mb-3"
                                            id="teachingModeFilter"
                                            label={`subcategorylevel2 FilterAlias`}
                                            checked={domainFormData?.teachingModeFilter === "show"}
                                            onChange={(e) => {
                                                const newValue = e.target.checked ? "show" : "hide";
                                                handleFieldChange("teachingModeFilter", newValue);
                                            }}
                                        />
                                    </Col>

                                    <Col sm={6}>
                                        <Form.Check
                                            type="checkbox"
                                            className="custom-checkbox ps-0 mb-3"
                                            id="tagsFilter"
                                            label={`subcategorylevel3 FilterAlias`}
                                            checked={domainFormData?.tagsFilter === "show"}
                                            onChange={(e) => {
                                                const newValue = e.target.checked ? "show" : "hide";
                                                handleFieldChange("tagsFilter", newValue);
                                            }}
                                        />
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Check
                                            type="checkbox"
                                            className="custom-checkbox ps-0 mb-3"
                                            id="ratingsFilter"
                                            label={`star_rating_type FilterAlias`}
                                            checked={domainFormData?.ratingsFilter === "show"}
                                            onChange={(e) => {
                                                const newValue = e.target.checked ? "show" : "hide";
                                                handleFieldChange("ratingsFilter", newValue);
                                            }}
                                        />
                                    </Col>
                                </>

                                <Col md={12} className="mt-3">

                                    <Button
                                        type="button"
                                        className="prev demosEditBtn"
                                        onClick={handlePrevious}
                                    >
                                        Prev
                                    </Button>

                                    <Button
                                        type="button"
                                        className="next demosEditBtn "
                                        onClick={handleNext}
                                    >
                                        Next
                                    </Button>
                                </Col>

                            </Row>
                        )}
                        {/* Step */}
                        {step === 6 && (
                            <Row className="mx-0">
                                {/*************** enums *****************/}
                                {/* UserRoles */}
                                <>
                                    <Col
                                        md={12}
                                        className="d-flex justify-content-between gap-3 mt-4 mb-2"
                                    >
                                        <h6 className="theme-text-2 flex-grow-1 text-capitalize">
                                            User Roles
                                        </h6>
                                        <Button
                                            className="clickable add-button ms-auto rounded-circle d-flex"
                                            onClick={addField}
                                        >
                                            <span className="addBttn d-block">+</span>
                                        </Button>
                                    </Col>
                                    <hr />
                                    <Col md={12} className="mt-3">
                                        <Col md={12} className="mt-3">
                                            {userRole?.map((role, index) => (
                                                <DynamicUserRoleFieldset
                                                    key={index}
                                                    field={role}
                                                    onFieldChange={(updatedRole) =>
                                                        updateUserRoles(index, updatedRole)
                                                    }
                                                    onRemove={() => removeField(index)}
                                                />
                                            ))}
                                        </Col>
                                    </Col>
                                </>
                                {/* Redirect Urls */}
                                <>
                                    <Col
                                        md={12}
                                        className="d-flex justify-content-between gap-3 mt-4 mb-2"
                                    >
                                        <h6 className="theme-text-2 flex-grow-1 text-capitalize">
                                            Dynamic URLs
                                        </h6>
                                        <Button
                                            className="clickable add-button ms-auto rounded-circle d-flex"
                                            onClick={() => setDynamicUrls((prevRole) => [...prevRole, { key: "", value: "" }])}
                                        >
                                            <span className="addBttn d-block">+</span>
                                        </Button>
                                    </Col>
                                    <hr />
                                    <Col md={12} className="mt-3">
                                        <Col md={12} className="mt-3">
                                            {dynamicUrls?.map((role, index) => (
                                                <DynamicUserRoleFieldset
                                                    keyLabel="Route"
                                                    valueLabel="Redirect URL"
                                                    key={index}
                                                    field={role}
                                                    onFieldChange={(updatedRole) =>
                                                        setDynamicUrls((prevRole) => {
                                                            const newRole = [...prevRole];
                                                            newRole[index] = updatedRole;
                                                            return newRole;
                                                        })
                                                    }
                                                    onRemove={() => setDynamicUrls((prevRole) => prevRole.filter((_, i) => i !== index))}
                                                />
                                            ))}
                                        </Col>
                                    </Col>
                                </>
                                {/* Countries */}
                                <>
                                    <Col
                                        md={12}
                                        className="d-flex justify-content-between gap-3 mt-4 mb-2"
                                    >
                                        <h6 className="theme-text-2 flex-grow-1 text-capitalize">
                                            country_types - label:{countryTypeFilterAlias}
                                        </h6>
                                        <Button
                                            className="clickable add-button ms-auto rounded-circle d-flex"
                                            onClick={() => {
                                                setCountryTypes((prevCountryTypes) => {
                                                    const newIndex =
                                                        Object.keys(
                                                            prevCountryTypes
                                                        )?.length.toString();
                                                    return {
                                                        ...prevCountryTypes,
                                                        [newIndex]: "",
                                                    };
                                                });
                                            }}
                                        >
                                            <span className="addBttn d-block">+</span>
                                        </Button>
                                    </Col>
                                    <hr />
                                    <Col md={12} className="mt-3">
                                        <Row>
                                            {countryTypes &&
                                                Object.keys(countryTypes)?.map((index) => (
                                                    <DynamicOptionsFieldset
                                                        key={index}
                                                        index={index}
                                                        fields={countryTypes}
                                                        setFields={setCountryTypes}
                                                    />
                                                ))}
                                        </Row>
                                    </Col>
                                </>
                                {/* Course Names */}
                                <>
                                    <Col
                                        md={12}
                                        className="d-flex justify-content-between gap-3 mt-4 mb-2"
                                    >
                                        <h6 className="theme-text-2 flex-grow-1 text-capitalize">
                                            Category - label:{coursesFilterAlias}
                                        </h6>
                                        <Button
                                            className="clickable add-button ms-auto rounded-circle d-flex"
                                            onClick={() => {
                                                setCourseNames((prevState) => {
                                                    const newIndex =
                                                        Object.keys(prevState)?.length.toString();
                                                    return {
                                                        ...prevState,
                                                        [newIndex]: "",
                                                    };
                                                });
                                            }}
                                        >
                                            <span className="addBttn d-block">+</span>
                                        </Button>
                                    </Col>
                                    <hr />
                                    <Col md={12} className="mt-3">
                                        <Row>
                                            {courseNames &&
                                                Object.keys(courseNames)?.map((index) => (
                                                    <DynamicOptionsFieldset
                                                        key={index}
                                                        index={index}
                                                        fields={courseNames}
                                                        setFields={setCourseNames}
                                                    />
                                                ))}
                                        </Row>
                                    </Col>
                                </>
                                {/* Subcategory Types */}
                                <>
                                    <Col
                                        md={12}
                                        className="d-flex justify-content-between gap-3 mt-4 mb-2"
                                    >
                                        <h6 className="theme-text-2 flex-grow-1 text-capitalize">
                                            subcategory - label:{learningLevelFilterAlias}
                                        </h6>
                                        <Button
                                            className="clickable add-button ms-auto rounded-circle d-flex"
                                            onClick={() => {
                                                setSubcategoryTypes((prevState) => {
                                                    const newIndex =
                                                        Object.keys(prevState)?.length.toString();
                                                    return {
                                                        ...prevState,
                                                        [newIndex]: "",
                                                    };
                                                });
                                            }}
                                        >
                                            <span className="addBttn d-block">+</span>
                                        </Button>
                                    </Col>
                                    <hr />
                                    <Col md={12} className="mt-3">
                                        <Row>
                                            {subcategoryTypes &&
                                                Object.keys(subcategoryTypes)?.map((index) => (
                                                    <DynamicOptionsFieldset
                                                        key={index}
                                                        index={index}
                                                        fields={subcategoryTypes}
                                                        setFields={setSubcategoryTypes}
                                                    />
                                                ))}
                                        </Row>
                                    </Col>
                                </>
                                {/* Payment Types */}
                                <>
                                    <Col
                                        md={12}
                                        className="d-flex justify-content-between gap-3 mt-4 mb-2"
                                    >
                                        <h6 className="theme-text-2 flex-grow-1 text-capitalize">
                                            subcategorylevel1 - label:{paymentFilterAlias}
                                        </h6>
                                        <Button
                                            className="clickable add-button ms-auto rounded-circle d-flex"
                                            onClick={() => {
                                                setPaymentTypes((prevState) => {
                                                    const newIndex =
                                                        Object.keys(prevState)?.length.toString();
                                                    return {
                                                        ...prevState,
                                                        [newIndex]: "",
                                                    };
                                                });
                                            }}
                                        >
                                            <span className="addBttn d-block">+</span>
                                        </Button>
                                    </Col>
                                    <hr />
                                    <Col md={12} className="mt-3">
                                        <Row>
                                            {paymentTypes &&
                                                Object.keys(paymentTypes)?.map((index) => (
                                                    <DynamicOptionsFieldset
                                                        key={index}
                                                        index={index}
                                                        fields={paymentTypes}
                                                        setFields={setPaymentTypes}
                                                    />
                                                ))}
                                        </Row>
                                    </Col>
                                </>

                                {/* Teaching Modes */}
                                <>
                                    <Col
                                        md={12}
                                        className="d-flex justify-content-between gap-3 mt-4 mb-2"
                                    >
                                        <h6 className="theme-text-2 flex-grow-1 text-capitalize">
                                            subcategorylevel2 - label:{teachingModesFilterAlias}
                                        </h6>
                                        <Button
                                            className="clickable add-button ms-auto rounded-circle d-flex"
                                            onClick={() => {
                                                setTeachingModes((prevState) => {
                                                    const newIndex =
                                                        Object.keys(prevState)?.length.toString();
                                                    return {
                                                        ...prevState,
                                                        [newIndex]: "",
                                                    };
                                                });
                                            }}
                                        >
                                            <span className="addBttn d-block">+</span>
                                        </Button>
                                    </Col>
                                    <hr />
                                    <Col md={12} className="mt-3">
                                        <Row>
                                            {teachingModes &&
                                                Object.keys(teachingModes)?.map((index) => (
                                                    <DynamicOptionsFieldset
                                                        key={index}
                                                        index={index}
                                                        fields={teachingModes}
                                                        setFields={setTeachingModes}
                                                    />
                                                ))}
                                        </Row>
                                    </Col>
                                </>
                                {/* StarRatings */}
                                <>
                                    <Col
                                        md={12}
                                        className="d-flex justify-content-between gap-3 mt-4 mb-2"
                                    >
                                        <h6 className="theme-text-2 flex-grow-1 text-capitalize">
                                            star_rating_type - label:{ratingsFilterAlias}
                                        </h6>
                                        <Button
                                            className="clickable add-button ms-auto rounded-circle d-flex"
                                            onClick={() => {
                                                setStarRatings((prevState) => {
                                                    const newIndex =
                                                        Object.keys(prevState)?.length.toString();
                                                    return {
                                                        ...prevState,
                                                        [newIndex]: "",
                                                    };
                                                });
                                            }}
                                        >
                                            <span className="addBttn d-block">+</span>
                                        </Button>
                                    </Col>
                                    <hr />
                                    <Col md={12} className="mt-3">
                                        <Row>
                                            {starRatings &&
                                                Object.keys(starRatings)?.map((index) => (
                                                    <DynamicOptionsFieldset
                                                        key={index}
                                                        index={index}
                                                        fields={starRatings}
                                                        setFields={setStarRatings}
                                                    />
                                                ))}
                                        </Row>
                                    </Col>
                                </>

                                {/***************  Subscriptions ********************/}
                                <>
                                    <Col md={12}>
                                        <h6 className="theme-text-2 fw-normal mt-3">
                                            Subscription Packages Details
                                        </h6>
                                    </Col>
                                    <hr />
                                    <Col md={6}>
                                        <small className="text-end d-block text-dark">
                                            required<span className="text-danger">*</span>
                                        </small>
                                        <Form.Group className="form-floating mb-3">
                                            <Form.Control
                                                type="text"
                                                id="basicSubscriptionsCharges"
                                                name="basicSubscriptionsCharges"
                                                placeholder="basicSubscriptionsCharges"
                                                value={domainFormData?.basicSubscriptionsCharges}
                                                onChange={(e) =>
                                                    handleFieldChange(
                                                        "basicSubscriptionsCharges",
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            <label htmlFor="basicSubscriptionsCharges">
                                                Basic Subscription Charges
                                            </label>
                                        </Form.Group>
                                    </Col>

                                    <Col md={6}>
                                        <small className="text-end d-block text-dark">
                                            required<span className="text-danger">*</span>
                                        </small>
                                        <Form.Group className="form-floating mb-3">
                                            <Form.Control
                                                type="text"
                                                id="proSubscriptionsCharges"
                                                name="proSubscriptionsCharges"
                                                placeholder="proSubscriptionsCharges"
                                                value={domainFormData?.proSubscriptionsCharges}
                                                onChange={(e) =>
                                                    handleFieldChange(
                                                        "proSubscriptionsCharges",
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            <label htmlFor="proSubscriptionsCharges">
                                                Professional Subscription Charges
                                            </label>
                                        </Form.Group>
                                    </Col>

                                    <Col md={6}>
                                        <small className="text-end d-block text-dark">
                                            required<span className="text-danger">*</span>
                                        </small>
                                        <Form.Group className="form-floating mb-3">
                                            <Form.Control
                                                type="text"
                                                id="masterSubscriptionsCharges"
                                                name="masterSubscriptionsCharges"
                                                placeholder="masterSubscriptionsCharges"
                                                value={domainFormData?.masterSubscriptionsCharges}
                                                onChange={(e) =>
                                                    handleFieldChange(
                                                        "masterSubscriptionsCharges",
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            <label htmlFor="masterSubscriptionsCharges">
                                                Master Subscription Charges
                                            </label>
                                        </Form.Group>
                                    </Col>

                                    {/* basic Package */}
                                    <Col
                                        md={12}
                                        className="d-flex justify-content-between gap-3 mt-4 mb-2"
                                    >
                                        <h6 className="theme-text-2 flex-grow-1">
                                            Basic Package Benefits
                                        </h6>
                                        <Button
                                            className="clickable add-button ms-auto rounded-circle d-flex"
                                            onClick={() => {
                                                setBasicPackage((prevState) => {
                                                    const newIndex =
                                                        Object.keys(prevState)?.length.toString();
                                                    return {
                                                        ...prevState,
                                                        [newIndex]: "",
                                                    };
                                                });
                                            }}
                                        >
                                            <span className="addBttn d-block">+</span>
                                        </Button>
                                    </Col>
                                    <hr />
                                    <Col md={12} className="mt-3">
                                        <Row>
                                            {basicPackage &&
                                                Object.keys(basicPackage)?.map((index) => (
                                                    <DynamicOptionsFieldset
                                                        label={"Benefit"}
                                                        key={index}
                                                        index={index}
                                                        fields={basicPackage}
                                                        setFields={setBasicPackage}
                                                    />
                                                ))}
                                        </Row>
                                    </Col>
                                    {/* pro Package */}
                                    <Col
                                        md={12}
                                        className="d-flex justify-content-between gap-3 mt-4 mb-2"
                                    >
                                        <h6 className="theme-text-2 flex-grow-1">
                                            Professional Package Benefits
                                        </h6>
                                        <Button
                                            className="clickable add-button ms-auto rounded-circle d-flex"
                                            onClick={() => {
                                                setProPackage((prevState) => {
                                                    const newIndex =
                                                        Object.keys(prevState)?.length.toString();
                                                    return {
                                                        ...prevState,
                                                        [newIndex]: "",
                                                    };
                                                });
                                            }}
                                        >
                                            <span className="addBttn d-block">+</span>
                                        </Button>
                                    </Col>
                                    <hr />
                                    <Col md={12} className="mt-3">
                                        <Row>
                                            {proPackage &&
                                                Object.keys(proPackage)?.map((index) => (
                                                    <DynamicOptionsFieldset
                                                        label={"Benefit"}
                                                        key={index}
                                                        index={index}
                                                        fields={proPackage}
                                                        setFields={setProPackage}
                                                    />
                                                ))}
                                        </Row>
                                    </Col>
                                    {/* master Package */}
                                    <Col
                                        md={12}
                                        className="d-flex justify-content-between gap-3 mt-4 mb-2"
                                    >
                                        <h6 className="theme-text-2 flex-grow-1">
                                            Master Package Benefits
                                        </h6>
                                        <Button
                                            className="clickable add-button ms-auto rounded-circle d-flex"
                                            onClick={() => {
                                                setMasterPackage((prevState) => {
                                                    const newIndex =
                                                        Object.keys(prevState)?.length.toString();
                                                    return {
                                                        ...prevState,
                                                        [newIndex]: "",
                                                    };
                                                });
                                            }}
                                        >
                                            <span className="addBttn d-block">+</span>
                                        </Button>
                                    </Col>
                                    <hr />
                                    <Col md={12} className="mt-3">
                                        <Row>
                                            {masterPackage &&
                                                Object.keys(masterPackage)?.map((index) => (
                                                    <DynamicOptionsFieldset
                                                        label={"Benefit"}
                                                        key={index}
                                                        index={index}
                                                        fields={masterPackage}
                                                        setFields={setMasterPackage}
                                                    />
                                                ))}
                                        </Row>
                                    </Col>
                                </>

                                {/***************  Custom Fields ********************/}
                                <Col
                                    md={12}
                                    className="d-flex justify-content-between gap-3 mt-4 mb-2"
                                >
                                    <h6 className="theme-text-2 flex-grow-1">
                                        Add Custom Fields
                                    </h6>
                                    <Button
                                        className="clickable add-button ms-auto rounded-circle d-flex"
                                        onClick={() => {
                                            const newField = { key: "", value: "" };
                                            setCustomFields([...customFields, newField]);
                                        }}
                                    >
                                        <span className="addBttn d-block">+</span>
                                    </Button>
                                </Col>
                                <hr />
                                <Col md={12}>
                                    {customFields?.map((field, index) => (
                                        <DynamicGeneralFieldset
                                            key={index}
                                            fields={customFields}
                                            index={index}
                                            onFieldChange={(newFields) => {
                                                setCustomFields(newFields);
                                            }}
                                        />
                                    ))}
                                </Col>


                                <Col md={12} className="mt-3">
                                    <Button
                                        type="button"
                                        className="prev demosEditBtn"
                                        onClick={handlePrevious}
                                    >
                                        Prev
                                    </Button>

                                    <Button
                                        type='submit'
                                        className="next demosEditBtn "
                                    >
                                        Submit
                                    </Button>
                                </Col>

                            </Row>
                        )}
                    </Form>
                </Container>
            )}

        </>
    )
}

export default AdminConfigsPage
